import React, { useState } from "react";
import AutoSuggest from "react-autosuggest";
import { useHistory } from "react-router";
import { usersRef } from "../firebase";
import { FannOther, FannOtherDataKeys } from "../types/Fann";

import "./FannrSearch.css";

export default function FannrSearch() {
  const history = useHistory();

  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<string[][]>([]);

  function getSuggestions(searchText: string) {
    if (searchText !== "") {
      usersRef
        .where(FannOtherDataKeys.usernameLowercase, ">=", searchText.toLowerCase())
        .where(FannOtherDataKeys.usernameLowercase, "<=", searchText.toLowerCase() + "\uf8ff")
        .limit(20)
        .get()
        .then((docs) => {
          let results: string[][] = [];
            docs.docs.forEach(doc => {
              try {
                const u = doc.data() as FannOther;
                const boldName = u.username.substring(0, searchText.length);
                const remainder = u.username.slice(searchText.length);
                results.push([boldName, remainder]);
              } catch (error) {
                console.log(error);
              }
            });
          setSuggestions(results);
        })
        .catch((error) => {
          console.log(error);
          setSuggestions([])
        });
    } else {
      setSuggestions([]);
    }
  }

  return (
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value);
          getSuggestions(value);
        }}
        onSuggestionSelected={(_, { suggestionValue }) =>
          history.push("/user/" + suggestionValue)
        }
        getSuggestionValue={suggestion => suggestion.join('')}
        renderSuggestion={suggestion => <span>@<b>{suggestion[0]}</b>{suggestion[1]}</span>}
        inputProps={{
          placeholder: "Search...",
          value: value,
          onChange: (_, { newValue, method }) => {
            setValue(newValue);
          }
        }}
      />
  );
};
