import React from "react";
import FannrAppBar from "./AppBar";
import Footer from "./Footer";

export default function BannerFooter(props: any) {

  return (
    <>
      <FannrAppBar />
      {props.children}
      <Footer />
    </>
  );
}
