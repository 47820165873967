import { Button, Typography } from "@material-ui/core";
import React, { PureComponent } from "react";
// import { Form } from "semantic-ui-react";
// import { connect } from "react-redux";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

export interface Props {
  onCrop: (url: string) => void;
  onSave?: (file: File) => void;
  onCancel?: () => void;
  withSrc?: string;
}

export interface State {
  src?: string;
  crop: Crop;
  croppedImageUrl?: string;
  croppedImage?: File;
  imageRef?: HTMLImageElement;
  fileName?: string;
}

class AvatarUploader extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      crop: {
        aspect: 1 / 1,
      },
      src: this.props.withSrc,
    };
  }

  handleFile(e?: React.ChangeEvent<HTMLInputElement>) {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({ src: fileReader?.result?.toString() || "" });
    };
    fileReader.readAsDataURL(e?.target.files ? e.target.files[0] : new Blob());
  }

  handleSubmit() {
    if (this.props.onSave && this.state.croppedImage) {
      this.props.onSave(this.state.croppedImage);
    }
  }

  handleCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onImageLoaded(image: HTMLImageElement) {
    this.setState({ imageRef: image });
  }

  onCropChange(crop: Crop) {
    this.setState({ crop: crop });
  }

  onCropComplete(crop: Crop) {
    if (this.state.imageRef && crop.width && crop.height && crop.x && crop.y) {
      this.getCroppedImg(
        this.state.imageRef,
        crop.height,
        crop.width,
        crop.x,
        crop.y
      );
    }
  }

  getCroppedImg(
    image: HTMLImageElement,
    height: number,
    width: number,
    x: number,
    y: number
  ) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    ctx?.drawImage(
      image,
      x * scaleX,
      y * scaleY,
      width * scaleX,
      height * scaleY,
      0,
      0,
      width,
      height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob || new Blob());
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result?.toString() || "", "cropped.jpg");
      };
    });
  }

  dataURLtoFile(dataurl: string, filename: string) {
    let arr = dataurl.split(","),
      mime = (arr[0].match(/:(.*?);/) || ["", ""])[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({
      croppedImage: croppedImage,
      croppedImageUrl: dataurl,
      fileName: filename,
    });
    this.props.onCrop(dataurl);
  }

  render() {
    const { crop, src } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">
          Click and drag to crop your pofile photo
        </Typography>
        <br />
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={(img) => this.onImageLoaded(img)}
            onComplete={(crop) => this.onCropComplete(crop)}
            onChange={(crop) => this.onCropChange(crop)}
            circularCrop={true}
          />
        )}
        <br />
        {this.props.onSave && (
          <Button
            variant="contained"
            color="primary"
            disabled={!this.state.croppedImageUrl}
            onClick={() => this.handleSubmit()}
            style={{ width: "100%" }}
          >
            {this.state.croppedImageUrl ? "save" : "save (crop required)"}
          </Button>
        )}
        {this.props.onCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.handleCancel()}
            style={{ width: "100%" }}
          >
            cancel
          </Button>
        )}
      </div>
    );
  }
}

export default AvatarUploader;
