import React, { useCallback, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import firebase, { auth, imagesRef, postsRef } from "../firebase";
import Modal from "react-modal";
import { TextField, Typography } from "@material-ui/core";
import { Post, PostContent } from "../types/Post";
import { UserContext } from "../context/UserContext";
import { v4 as uuidv4 } from 'uuid';
import "./NewPostUploader.css";
import "react-slideshow-image/dist/styles.css";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { reorderContentWithID } from "../utility/shortcuts";

export interface ContentData {
  index: number;
  indexOriginal: number;
  dataURL: string,
  file: File,
  dbURL?: string,
  error?: boolean,
}

export interface Props {
  onDone?: () => void;
  onModalClose?: () => void;
  onModalOpen?: () => void;
  style?: React.CSSProperties;
}

export const slideProperties = {
  duration: 5000,
  autoplay: false,
  transitionDuration: 500,
  infinite: true,
  easing: "ease",
}

export default function NewPostUploader(props: Props) {
  const [content, setContent] = useState<ContentData[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [postDescription, setPostDescription] = useState("");

  const { state } = useContext(UserContext);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showModal) {
      props.onModalOpen && props.onModalOpen();
    } else {
      props.onModalClose && props.onModalClose();
    }
  }, [showModal, props])

  function uploadButtonClicked() {
    setContent([]);
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  }

  // function to read file as dataURL and return
  function getFileFromInput(file: File): Promise<any> {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(file); // here the file can be read in different way Text, DataUrl, ArrayBuffer
    })
  }

  const handleFiles = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.files) {
      let newContent = [...content];
      const initialContentCount = newContent.length;

      Array.from(event.target.files).forEach((file, index) => {
        getFileFromInput(file)
          .then((dataURL) => {
            newContent.push({index: index + initialContentCount, indexOriginal: index + initialContentCount, dataURL: dataURL, file: file});
            if (index === (event.target.files?.length || 1) - 1) {
              setContent(newContent);
              setShowModal(true);
            }
          }).catch(function (reason) {
              console.log(`Error during upload ${reason}`);
              event.target.value = ''; // to allow upload of same file if error occurs
          });
      });
    }
  }, [content])

  function handleFileCancel() {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }
    setContent([]);
    setShowModal(false);
  }

const handleSave = useCallback(() => {
  const timeStamp = Date.now();
  const postID = uuidv4();
  const promises: firebase.storage.UploadTask[] = [];
  const newContent = content;
  newContent.filter(x => !x.dbURL).forEach((img, index) => {
    const uploadTask = imagesRef.child("posts/" + postID + "/" + uuidv4()).put(img.file);
    promises.push(uploadTask);
    uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (snapshot.state === firebase.storage.TaskState.RUNNING) {
              console.log(`Progress: ${progress}%`);
            }
          },
          (error) => console.log(error),
          async () => {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            //convert to useCallback() with actual content?
            newContent[index].dbURL = downloadURL;
            if (newContent.filter(x => !x.dbURL).length === 0) {
              setContent(newContent);
              const postUid = auth.currentUser?.uid || state.user?.uid || "";
              const newContentData: PostContent[] = newContent.map((data) => {
                return {
                    contentUrl: data.dbURL || "",
                    contentIndex: data.index,
                    contentType: "image"
                  };
                }
              );
              let newPost: Post = {
                uploadTime: timeStamp,
                postTime: timeStamp,
                ownership: [{id: postUid, split: 1}],
                ownerUids: [postUid],
                ownerUidsDeleted: [],
                content: newContentData,
                desription: postDescription,
              }
              postsRef
                .doc(postID)
                .set(newPost)
                .then(function () {
                  handleFileCancel();
                  props.onDone && props.onDone();
                })
                .catch(function (error) {
                  console.log(error);
                })
            }
          });
        });
    Promise.all(promises)
    // .then(() => {}) //no action needed here
    .catch((err) => console.log(err));
  }, [content, postDescription, props, state.user]);

  const grid = 8;

  function getItemStyle(isDragging: boolean, draggableStyle: React.CSSProperties): React.CSSProperties {
    return {
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: grid * 1.25,
      margin: `0 ${grid}px 0 0`,

      // change background colour if dragging
      background: isDragging ? 'lightgreen' : 'grey',

      // styles we need to apply on draggables
      ...draggableStyle,
    }
  };

  function getListStyle(isDraggingOver: boolean): React.CSSProperties {
    return {
      background: isDraggingOver ? 'lightblue' : 'lightgrey',
      display: 'flex',
      padding: grid,
      overflow: 'auto',
    }
  };

  const onDragEnd = useCallback((result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderContentWithID(
      content,
      result.source.index,
      result.destination.index
    );

    setContent(items);
  }, [content])

  return (
    <>
      <Button variant="contained" color="primary" style={props.style} onClick={() => uploadButtonClicked()}>
        New Post
      </Button>
      <input
        multiple
        type="file"
        accept="image/*"
        id="profile_pic"
        ref={hiddenFileInput}
        onChange={handleFiles}
        style={{ display: "none" }}
      />
      <Modal
        isOpen={showModal}
        onRequestClose={handleFileCancel}
        style={{
          content: {
            height: "90vh",
            width: "80vw",
            marginTop: "5vh",
            marginLeft: "10vh",
          },
          overlay: {
            zIndex: 10000,
          }
        }}
      >
        <div style={{backgroundColor: "white", maxWidth: "100%", color:"black"}}>
          <Typography align="center">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {content.map((item, index) => (
                      <Draggable key={item.indexOriginal} draggableId={item.indexOriginal.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style || {}
                            )}
                          >
                            <div key={index} className="each-slide">
                              <img alt={"post_content_" + index} src={item.dataURL} style={{maxHeight: "30vh", objectFit: "contain"}} />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <TextField
              multiline
              rowsMax={5}
              variant="outlined"
              label="Post description"
              value={postDescription}
              onChange={(e) => setPostDescription(e.target.value)}
              style={{ width:"90%", margin: 5 }}
            />
            <Button style={{width: "90%", margin: 5}} variant="contained" color="primary" onClick={handleSave}>Save</Button>
            <Button style={{width: "90%", margin: 5}} variant="contained" color="secondary" onClick={handleFileCancel}>Cancel</Button>
          </Typography>
        </div>
      </Modal>
    </>
  );
}
