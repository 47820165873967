import { FannSelf } from "../types/Fann";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};
export enum Types {
  SetValue = "SET_VALUE",
  SetUser = "SET_USER",
  RemoveUser = "REMOVE_USER",
  UpdateBio = "UPDATE_BIO",
  UpdateCatchphrase = "UPDATE_CATCHPHRASE",
  UpdateDisplayName = "UPDATE_DISPLAY_NAME",
}
type UserPayload = {
  [Types.RemoveUser]: undefined;
  [Types.SetUser]: {
    user: FannSelf;
  };
  [Types.UpdateBio]: {
    bio: string;
  };
  [Types.UpdateCatchphrase]: {
    catchPhrase: string;
  };
  [Types.UpdateDisplayName]: {
    displayName: string;
  };
};
export type UserActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export const userReducer = (
  state: FannSelf | undefined,
  action: UserActions
): FannSelf | undefined => {
  if (state) {
    switch (action.type) {
      case Types.SetUser:
        return action.payload.user;
      case Types.UpdateBio:
        return {
          ...state,
          bio: action.payload.bio,
        };
      case Types.UpdateCatchphrase:
        return {
          ...state,
          catchPhrase: action.payload.catchPhrase,
        };
      case Types.UpdateDisplayName:
        return {
          ...state,
          displayName: action.payload.displayName,
        };
      case Types.RemoveUser:
        return undefined
      default:
        return state;
    }
  } else {
    switch (action.type) {
      case Types.SetUser:
        return action.payload.user;
      case Types.RemoveUser:
        return undefined
      default:
        return undefined;
    }
  }
};

type MessageCountPayload = {
  [Types.SetValue]: {value: number};
}

export type MessageCountActions = ActionMap<MessageCountPayload>[keyof ActionMap<MessageCountPayload>];

export const messageCountReducer = (state: number, action: MessageCountActions) => {
  switch (action.type) {
    case Types.SetValue:
      return action.payload.value;
    default:
      return state;
  }
}

type NotificationCountPayload = {
  [Types.SetValue]: {value: number};
}

export type NotificationCountActions = ActionMap<NotificationCountPayload>[keyof ActionMap<NotificationCountPayload>];

export const notificationCountReducer = (state: number, action: NotificationCountActions) => {
  switch (action.type) {
    case Types.SetValue:
      return action.payload.value;
    default:
      return state;
  }
}
