import React, { useState, useContext } from "react";
import { auth, usersRef } from "../firebase";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import { UserContext } from "../context/UserContext";
import {Types} from "../context/UserReducer";
import {FannSelf} from "../types/Fann";

export default function SignInRequired(props: any) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [signUpMode, setSignUpMode] = useState(false);
  const { state, dispatch } = useContext(UserContext);

  auth.onAuthStateChanged(function (authUser) {
    if (authUser) {
      if (!loggedIn || !(state.user?.uid === authUser.uid)) {
        setLoggedIn(true);

        usersRef
          .doc(authUser.uid)
          .onSnapshot((doc) => {
            if (doc.exists) {
                const fann = doc.data() as FannSelf;
                dispatch({
                  type: Types.SetUser,
                  payload: {
                    user: fann
                  },
                })
              }
            }, (error) => {
              console.log(error);
            }
          );
    }
    } else {
      if (loggedIn) {
        setLoggedIn(false);
        dispatch({type: Types.RemoveUser})
      }
    }
  });

  function toggleSignUpMode(signUp: boolean) {
    setSignUpMode(signUp);
  }

  return (
    <>
      {!loggedIn && !signUpMode && (
        <SignIn
          withoutReroute={true}
          onSwitchToSignup={() => toggleSignUpMode(true)}
        />
      )}
      {!loggedIn && signUpMode && (
        <SignUp
          withoutReroute={true}
          onSwitchToSignin={() => toggleSignUpMode(false)}
        />
      )}
      {loggedIn && (
        <>
          {props.children}
        </>
      )}
    </>
  );
}
