import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";

interface faq {
  Header: string;
  Description: any;
}

export default function FAQS() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [showFaqs, setShowFaqs] = useState(false);
  const [buttonText, setButtonText] = useState("");

  const buttonTextOptions = [
    "Wait, what is this?",
    "Tell me more pls",
    "Click here for more information",
    "Can you explain more?",
    "How does this work?",
  ];

  useEffect(() => {
    if (!isLoaded) {
      setButtonText(shuffle(buttonTextOptions)[0]);
      setIsLoaded(true)
    }
  }, [isLoaded, buttonTextOptions])

  function shuffle<T>(array: T[]): T[] {
    if (!Array.isArray(array)) {
      throw new TypeError(`Expected an Array, got ${typeof array} instead.`);
    }

    const oldArray = [...array];
    let newArray = new Array<T>();

    while (oldArray.length) {
      const i = Math.floor(Math.random() * oldArray.length);
      newArray = newArray.concat(oldArray.splice(i, 1));
    }

    console.log(newArray);
    return newArray;
  }

  const faqs: faq[] = [
    {
      Header: "1. What it Fannr about?",
      Description: (
        <Typography>
          Fannr is being created with the goal of providing more power and
          visibility to content creators in the sex work industry.
          <ul>
            <li>
              It works like a regular subscription-based content platform (like{" "}
              <Link href="https://onlyfans.com/">
                <i>OnlyFans</i>
              </Link>
              ) but it's FREE for the users!
              <ul>
                <li>
                  A site for other creative content (more along the lines of{" "}
                  <Link href="https://patreon.com/">
                    <i>Patreon</i>
                  </Link>
                  ) may come as well, but this is my initial target market
                </li>
              </ul>
            </li>
            <li>
              Revenue will come from ads and tips (explained further in #2)
            </li>
            <li>
              The site is also intended to serve to 'expand your fannbase' and
              gain followers for other platforms you may be using.
            </li>
          </ul>
        </Typography>
      ),
    },
    {
      Header: "2. How do I earn money?",
      Description: (
        <Typography>
          Revenue will come in the form of ads and tips.
          <ul>
            <li>
              As a creator, you will get full visibility into the ad revenue
              associated with your content.
            </li>
            <ul>
              <li>Ads loaded on your page get fully attributed to you.</li>
              <li>
                Ads loaded on feed pages <i>(hot/trending, new, subscribed)</i>{" "}
                will be split evenly based on the content loaded on the page.
              </li>
            </ul>
            <li>
              Percentage and timing of payouts from ads/tips to users are
              currently undetermined (explained further in #3)
            </li>
          </ul>
        </Typography>
      ),
    },
    {
      Header: "3. How much can I earn?",
      Description: (
        <Typography>
          I currently{" "}
          <b>
            <i>do not know</i>
          </b>{" "}
          how much ad revenue can be expected or how long it takes for funds to
          come in and be processed to pay out. This makes it difficult to
          determine a set rate/timeline for your income. <br />
          <br />
          <b>
            My approach to this problem is integral to my goal of providing more
            power to you, the creator:
          </b>
          <ul>
            <li>Tips will be split at a set rate, currently undetermined</li>
            <li>
              I will make the following information available to all creators:
              <ul>
                <li>Total page views + your view count</li>
                <li>Total feed impressions + your impression count</li>
                <li>Total ad revenue by type</li>
                <li>Total expenses associated with running the site</li>
                <li>
                  Remainder needed to cover expenses after applying site's cut
                  of the tips
                </li>
                <li>Total remainder + your calculated payout</li>
              </ul>
              <li>
                Once we are able to determine a flat rate, you get a say in how
                funds are utilized (explained further in #4 below)
              </li>
            </li>
          </ul>
        </Typography>
      ),
    },
    {
      Header: "4. How does this site 'empower creators'?",
      Description: (
        <Typography>
          The main idea is to give creators visibility into and ownership of how
          the website works, grows, and makes decisions. Think of it like a
          'credit union for porn'.
          <ul>
            <li>
              In regards to revenue and payments, this is largely explained in
              question #3 above
            </li>
            <li>
              Once a certain payout percentage becomes possible, a fixed
              percentage will be determined and{" "}
              <b>creators will get to vote on how extra funds get applied</b>
              <ul>
                <li>
                  Website improvements: things like automation or services that
                  would improve site performance but cost money
                </li>
                <li>
                  Developer funds: can help speed up website progress
                  <ul>
                    <li>
                      Potentially removing the developer's need to have a full
                      time job (
                      <span aria-label="right" role="img">
                        👉
                      </span>
                      <span aria-label="left" role="img">
                        👈
                      </span>
                      <span aria-label="pls" role="img">
                        🥺
                      </span>
                      )
                    </li>
                    <li>
                      Add more people to work on the team (developers,
                      designers, etc)
                    </li>
                    <li>
                      <b>
                        Once development is funded by the site, creators will
                        get to vote on which features are worked on and when
                      </b>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </Typography>
      ),
    },
    {
      Header: "5. How does my content get seen?",
      Description: (
        <Typography>
          <ol>
            <li>Your personal page with username-base url</li>
            <li>
              Users will be able to browse post/user feeds sorted by time,
              popularity, and tags/categories
            </li>
          </ol>
        </Typography>
      ),
    },
    {
      Header: "6. What happens when I sign up?",
      Description: (
        <Typography>
          <ul>
            <li>
              You will get an email confirmation request and I will notifiy you
              when further account setup is possible.
            </li>
            <li>
              As far as the UI, it will navigate you to a page that is just a
              template that does nothing.
            </li>
            <li>
              Your profile will be given an elite and highly sought after Early
              Adopter status.
            </li>
          </ul>
        </Typography>
      ),
    },
    {
      Header: "7. What can I do to help?",
      Description: (
        <Typography>
          This is currently just one developer's passion project with no short
          term plans (or ability or funds) to advertise or increase production
          capabilities,
          <ul>
            <li>
              <b>Sign up now!</b> And be ready to come back and set up your
              profiles and add content when the site is ready :)
            </li>
            <li>
              <b>Word of mouth</b> will be huge once the site is ready for
              vistors
            </li>
            <li>Feedback and ideas!</li>
            <li>Are you good at design? (I'm not)</li>
            <li>Possible help with merch design/sourcing/creation?</li>
            <li>Know how to code?</li>
          </ul>
        </Typography>
      ),
    },
  ];

  return (
    <div>
      <Button
        color="primary"
        onClick={() => setShowFaqs(!showFaqs)}
        style={{width: "100%"}}
        >
        {showFaqs ? "Hide" : buttonText}
      </Button>
      {showFaqs && (
        <>
          {faqs.map((faq, index) => {
            return (
              faq.Header !== "" && (
                <Accordion style={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={"panel" + index + "-content"}
                    id={"panel" + index + "-header"}
                  >
                    <Typography>{faq.Header}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>{faq.Description}</AccordionDetails>
                </Accordion>
              )
            );
          })}
          <Button
            color="primary"
            onClick={() => setShowFaqs(!showFaqs)}
            style={{width: "100%"}}
          >
            Hide
          </Button>
        </>
      )}
    </div>
  );

}
