export interface Comment {
  commentTime: number;
  postID: string;
  userID: string;
  commentText: string;
  replies: Comment[]
  likes: string[]
}

export interface PostOwnership {
  id: string;
  split: number;
}

export interface PostContent {
  contentUrl: string;
  contentIndex: number;
  contentType: string;
}

export interface Post {
  uploadTime: number;
  postTime: number;
  ownership: PostOwnership[];
  ownerUids: string[];
  ownerUidsDeleted: string[];
  content: PostContent[];
  desription: string;
}

export const PostStrings = {
  uploadTime: "uploadTime",
  postTime: "postTime",
  ownership: "ownership",
  ownerUids: "ownerUids",
  ownerUidsDeleted: "ownerUidsDeleted",
  imageURLs: "imageURLs",
  desription: "desription",
}
