import LocalizedStrings from "localized-strings";

interface SignupStrings {
  GET_NEWSLETTER_BETA: string;
  GET_NEWSLETTER_CREATOR: string;
  GET_NEWSLETTER_GENERAL: string;
  JOIN_BETA: string;
  JOIN_BETA_TOOLTIP: string;
}

export const SIGNUP_STRINGS = new LocalizedStrings<SignupStrings>({
  en: {
    GET_NEWSLETTER_BETA: "Get beta related email updates?",
    GET_NEWSLETTER_CREATOR: "Get creator related email updates?",
    GET_NEWSLETTER_GENERAL: "Get general email updates?",
    JOIN_BETA: "I want to be a Fannr Beta member",
    JOIN_BETA_TOOLTIP: "You will experience features that are being tested and have an opportunity to provide feedback on them",
  },
});
interface SignupValidationStrings {
  CHECKING_USERNAME: string;
  DOB_REQUIRED: string;
  EMAIL_REQUIRED: string;
  EMAIL_NOT_VALID: string;
  EMAILS_DONT_MATCH: string;
  FIELD_REQUIRED: string;
  MUST_BE_18: string;
  PASSWORD_REQUIRED: string;
  PASSWORD_MIN_LENGTH: string;
  PASSWORDS_DONT_MATCH: string;
  USERNAME_AVAILABLE: string;
  USERNAME_CHECK_ERROR: string;
  USERNAME_TAKEN: string;
}

export const SIGNUP_VALIDATION_STRINGS = new LocalizedStrings<SignupValidationStrings>({
  en: {
    CHECKING_USERNAME: "Checking username availability...",
    DOB_REQUIRED: "Date of birth is required",
    EMAIL_REQUIRED: "Email is required",
    EMAIL_NOT_VALID: "Valid email address is required",
    EMAILS_DONT_MATCH: "Emails don't match",
    FIELD_REQUIRED: "This field is required",
    MUST_BE_18: "Must be at least 18 years old",
    PASSWORD_REQUIRED: "Password required",
    PASSWORD_MIN_LENGTH: "Password must be at least 6 characters",
    PASSWORDS_DONT_MATCH: "Passwords don't match",
    USERNAME_AVAILABLE: "Username is available :)",
    USERNAME_CHECK_ERROR: "Error checking username availability, try again",
    USERNAME_TAKEN: "Username not available :(",
  },
});
