import { Post } from "./Post"
export interface FannOther {
  lastLogin: number;
  lastActive: number;
  photoURL: string | null;
  uid: string;
  username: string;
  usernameLowercase: string;
  displayName: string;
  bio?: string;
  catchPhrase?: string;
  posts?: Post[];
}

export interface FannSelf extends FannOther {
  emailVerified: boolean;
  email: string | null;
  phoneNumber: string | null;
  nameFirst?: string;
  nameLast?: string;
  dob: string;
}

export interface AccountSettings {
  allowCommentsPosts: boolean;
  allowCommentsProfile: boolean;
  beta: boolean;
  newsletterBeta: boolean;
  newsletterCreator: boolean;
  newsletterGeneral: boolean;
  uid: string;
}

export const FannOtherDataKeys = {
  lastLogin: "lastLogin",
  lastActive: "lastActive",
  photoURL: "photoURL",
  uid: "uid",
  username: "username",
  usernameLowercase: "usernameLowercase",
  displayName: "displayName",
  bio: "bio",
  catchPhrase: "catchPhrase",
  posts: "posts",
};

export const FannSelfDataKeys = {
  ...FannOtherDataKeys,
  emailVerified: "emailVerified",
  email: "email",
  phoneNumber: "phoneNumber",
  nameFirst: "nameFirst",
  nameLast: "nameLast",
  dob: "dob",
}

export const AccountSettingsStrings = {
  allowCommentsPosts: "allowCommentsPosts",
  allowCommentsProfile: "allowCommentsProfile",
  beta: "beta",
  newsletterBeta: "newsletterBeta",
  newsletterCreator: "newsletterCreator",
  newsletterGeneral: "newsletterCreator",
  uid: "uid",
}
