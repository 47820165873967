import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import { auth, imagesRef, usersRef, settingsRef, hardcodedProdUsersRef, hardcodedProdSettingsRef } from "../firebase";
import { FannOtherDataKeys, FannSelf, AccountSettings } from "../types/Fann";
import AvatarUploader from "../components/AvatarUploader";
import FAQS from "../components/Faqs";
import Modal from "@material-ui/core/Modal";
import { DateDiff } from "../utility/dateStuff"
import { SIGNUP_STRINGS, SIGNUP_VALIDATION_STRINGS } from "../utility/strings"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://fannr.net/">
        Fannr
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    width: "10em",
    height: "10em",
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export interface Props {
  onSwitchToSignin?: () => void;
  withoutReroute?: boolean;
}

export default function SignUp(props: Props) {
  const history = useHistory();
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [croppedImageURL, setCroppedImageURL] = useState<string>();
  const [avatarFileSrc, setAvatarFileSrc] = useState<string>();
  const [avatarFile, setAvatarFile] = useState<File>();
  const [dob, setDob] = useState<string>();

  const [validDob, setValidDob] = useState(false);
  const [validUsername, setValidUsername] = useState(false);
  const [validDisplayName, setValidDisplayName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validEmailVerify, setValidEmailVerify] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [validPasswordVerify, setValidPasswordVerify] = useState(false);
  const [canSignUp, setCanSignUp] = useState(false);

  const [canValidateDob, setCanValidateDob] = useState(false);
  const [canValidateUsername, setCanValidateUsername] = useState(false);
  const [canValidateDisplayName, setCanValidateDisplayName] = useState(false);
  const [canValidateEmail, setCanValidateEmail] = useState(false);
  const [canValidateEmailVerify, setCanValidateEmailVerify] = useState(false);
  const [canValidatePassword, setCanValidatePassword] = useState(false);
  const [canValidatePasswordVerify, setCanValidatePasswordVerify] = useState(false);
  const [canValidateAll, setCanValidateAll] = useState(false);

  const [inputErrorDob, setInputErrorDob] = useState(false);
  const [inputErrorUsername, setInputErrorUsername] = useState(false);
  const [inputErrorDisplayName, setInputErrorDisplayName] = useState(false);
  const [inputErrorEmail, setInputErrorEmail] = useState(false);
  const [inputErrorEmailVerify, setInputErrorEmailVerify] = useState(false);
  const [inputErrorPassword, setInputErrorPassword] = useState(false);
  const [inputErrorPasswordVerify, setInputErrorPasswordVerify] = useState(false);

  const [inputHelperTextDob, setInputHelperTextDob] = useState<string>();
  const [inputHelperTextUsername, setInputHelperTextUsername] = useState<string>();
  const [inputHelperTextDisplayName, setInputHelperTextDisplayName] = useState<string>();
  const [inputHelperTextEmail, setInputHelperTextEmail] = useState<string>();
  const [inputHelperTextEmailVerify, setInputHelperTextEmailVerify] = useState<string>();
  const [inputHelperTextPassword, setInputHelperTextPassword] = useState<string>();
  const [inputHelperTextPasswordVerify, setInputHelperTextPasswordVerify] = useState<string>();

  const [duplicateOnProd, setDuplicateOnProd] = useState(false);
  const [betaMember, setBetaMember] = useState(false);
  const [getNewsletterBeta, setGetNewLetterBeta] = useState(false);
  const [getNewsletterCreator, setGetNewLetterCreator] = useState(false);
  const [getNewsletterGeneral, setGetNewLetterGeneral] = useState(false);

  const maxDisplayNameCharacters = 15;

  useEffect(() => {
    setCanSignUp(
      validDob &&
      validUsername &&
      validDisplayName &&
      validEmail &&
      validEmailVerify &&
      validPassword &&
      validPasswordVerify
      );
  }, [
    validDob,
    validUsername,
    validDisplayName,
    validEmail,
    validEmailVerify,
    validPassword,
    validPasswordVerify,
  ])

  useEffect(() => {
    if (canValidateDob || canValidateAll) {
      console.log("here");
      if (dob) {
        const dateDiff = new DateDiff(new Date(dob), new Date()).diffYears()
        const isUnderage = dateDiff < 18
        if (isUnderage) {
          setValidDob(false);
          setInputErrorDob(true);
          setInputHelperTextDob(SIGNUP_VALIDATION_STRINGS.MUST_BE_18);
        } else {
          setValidDob(true);
          setInputErrorDob(false);
          setInputHelperTextDob(undefined);
        }
      } else {
        setValidDob(false);
        setInputErrorDob(true);
        setInputHelperTextDob(SIGNUP_VALIDATION_STRINGS.DOB_REQUIRED);
      }
    } else {
      setValidDob(false);
      setInputErrorDob(false);
      setInputHelperTextDob(undefined);
    }
  }, [canValidateDob, canValidateAll, dob])

  useEffect(() => {
    if (canValidateUsername || canValidateAll) {
      if (username === "") {
        setValidUsername(false);
        setInputErrorUsername(true);
        setInputHelperTextUsername(SIGNUP_VALIDATION_STRINGS.FIELD_REQUIRED);
      } else {
        setInputHelperTextUsername(SIGNUP_VALIDATION_STRINGS.CHECKING_USERNAME);
        usersRef
          .where(FannOtherDataKeys.usernameLowercase, "==", username.toLowerCase())
          .limit(1)
          .get()
          .then((docs) => {
            console.log(docs.docs);
            if (!docs.empty) {
              setValidUsername(false);
              setInputErrorUsername(true);
              setInputHelperTextUsername(SIGNUP_VALIDATION_STRINGS.USERNAME_TAKEN);
            } else {
              setValidUsername(true);
              setInputErrorUsername(false);
              setInputHelperTextUsername(SIGNUP_VALIDATION_STRINGS.USERNAME_AVAILABLE);
            }
          })
          .catch((error) => {
            console.log(error);
            setValidUsername(false);
            setInputErrorUsername(true);
            setInputHelperTextUsername(SIGNUP_VALIDATION_STRINGS.USERNAME_CHECK_ERROR);
          });
      }
    } else {
      setValidUsername(false);
      setInputErrorUsername(false);
      setInputHelperTextUsername(undefined);
    }
  }, [canValidateUsername, canValidateAll, username])

  useEffect(() => {
    if (canValidateDisplayName || canValidateAll) {
      if (displayName === "") {
        setValidDisplayName(false);
        setInputErrorDisplayName(true);
        setInputHelperTextDisplayName(SIGNUP_VALIDATION_STRINGS.FIELD_REQUIRED);
      } else {
        setValidDisplayName(true);
        setInputErrorDisplayName(false);
        setInputHelperTextDisplayName(displayName.length.toString() + "/" + maxDisplayNameCharacters.toString());
      }
    } else {
      setValidDisplayName(false);
      setInputErrorDisplayName(false);
      setInputHelperTextDisplayName(undefined);
    }
  }, [canValidateDisplayName, canValidateAll, displayName])

  useEffect(() => {
    if (canValidateAll || canValidateEmail) {
      if (email.trim() !== "") {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
          setValidEmail(true);
          setInputErrorEmail(false);
          setInputHelperTextEmail(undefined);
        } else {
          setValidEmail(false);
          setInputErrorEmail(true);
          setInputHelperTextEmail(SIGNUP_VALIDATION_STRINGS.EMAIL_NOT_VALID);
        }
      } else {
        setValidEmail(false);
        setInputErrorEmail(true);
        setInputHelperTextEmail(SIGNUP_VALIDATION_STRINGS.EMAIL_REQUIRED);
      }
    } else {
      setValidEmail(false);
      setInputErrorEmail(false);
      setInputHelperTextEmail(undefined);
    }
  }, [canValidateEmail, canValidateAll, email])

  useEffect(() => {
    if (canValidateAll || canValidateEmailVerify) {
      if (emailConfirm.trim() !== "") {
        if (emailConfirm === email) {
          setValidEmailVerify(true);
          setInputErrorEmailVerify(false);
          setInputHelperTextEmailVerify(undefined);
        } else {
          setValidEmailVerify(false);
          setInputErrorEmailVerify(true);
          setInputHelperTextEmailVerify(SIGNUP_VALIDATION_STRINGS.EMAILS_DONT_MATCH);
        }
      } else {
        setValidEmailVerify(false);
        setInputErrorEmailVerify(true);
        setInputHelperTextEmailVerify(SIGNUP_VALIDATION_STRINGS.FIELD_REQUIRED);
      }
    } else {
      setValidEmailVerify(false);
      setInputErrorEmailVerify(false);
      setInputHelperTextEmailVerify(undefined);
    }
  }, [canValidateEmailVerify, canValidateAll, emailConfirm, email])

  useEffect(() => {
    if (canValidateAll || canValidatePassword) {
      if (password !== "") {
        if (password.length >= 6)  {
          setValidPassword(true);
          setInputErrorPassword(false);
          setInputHelperTextPassword(undefined);
        } else {
          setValidPassword(false);
          setInputErrorPassword(true);
          setInputHelperTextPassword(SIGNUP_VALIDATION_STRINGS.PASSWORD_MIN_LENGTH);
        }
      } else {
        setValidPassword(false);
        setInputErrorPassword(true);
        setInputHelperTextPassword(SIGNUP_VALIDATION_STRINGS.PASSWORD_REQUIRED);
      }
    } else {
      setValidPassword(false);
      setInputErrorPassword(false);
      setInputHelperTextPassword(undefined);
    }
  }, [canValidatePassword, canValidateAll, password])

  useEffect(() => {
    if (canValidateAll || canValidatePasswordVerify) {
      if (passwordConfirm !== "") {
        if (passwordConfirm === password)  {
          setValidPasswordVerify(true);
          setInputErrorPasswordVerify(false);
          setInputHelperTextPasswordVerify(undefined);
        } else {
          setValidPasswordVerify(false);
          setInputErrorPasswordVerify(true);
          setInputHelperTextPasswordVerify(SIGNUP_VALIDATION_STRINGS.PASSWORDS_DONT_MATCH);
        }
      } else {
        setValidPasswordVerify(false);
        setInputErrorPasswordVerify(true);
        setInputHelperTextPasswordVerify(SIGNUP_VALIDATION_STRINGS.FIELD_REQUIRED);
      }
    } else {
      setValidPasswordVerify(false);
      setInputErrorPasswordVerify(false);
      setInputHelperTextPasswordVerify(undefined);
    }
  }, [canValidatePasswordVerify, canValidateAll, passwordConfirm, password])

  function SignUp(event: React.MouseEvent) {
    event.preventDefault();
    setCanValidateAll(true);
    createAccount();
  }

  function createAccount() {
    if (
      dob &&
      username !== "" &&
      displayName !== "" &&
      email !== "" &&
      emailConfirm === email &&
      password !== "" &&
      passwordConfirm === password
    ) {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then(function (result) {
          if (result?.user) {
            const user = result.user;
            const timeStamp = Date.now();
            const newUser: FannSelf = {
              email: user.email,
              emailVerified: user.emailVerified,
              phoneNumber: user.phoneNumber,
              photoURL: user.photoURL,
              uid: user.uid,
              displayName: displayName,
              username: username,
              usernameLowercase: username.toLowerCase(),
              lastLogin: timeStamp,
              lastActive: timeStamp,
              dob: dob,
            };
            if (avatarFile) {
              addAvatar(newUser, avatarFile);
            } else {
              addUser(newUser, "");
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  function addAvatar(user: FannSelf, file: File) {
    imagesRef
      .child("profile/" + user.uid)
      .put(file)
      .then(function (result) {
        result.ref.getDownloadURL().then(function (res) {
          addUser(user, res);
        }).catch(function (error) {
          console.log(error);
          addUser(user, "");
        })
      })
      .catch(function (error) {
        console.log(error);
        addUser(user, "");
      });
  }

  function addUser(user: FannSelf, avatarURL: string) {
    const addedUser: FannSelf = {...user, photoURL: avatarURL}
    usersRef
      .doc(addedUser.uid)
      .set(addedUser)
      .then(function () {
        if (duplicateOnProd) {
          hardcodedProdUsersRef
            .doc(addedUser.uid)
            .set(addedUser)
            .then(function () {
              addSettings(addedUser)
            })
            .catch(function (error) {
              console.log(error);
              addSettings(addedUser);
            })
        } else {
          addSettings(addedUser);
        }
      })
      .catch(function (error) {
        console.log(error);
        addSettings(addedUser);
      })
  }

  function addSettings(user: FannSelf) {
    const settings: AccountSettings = {
      allowCommentsPosts: true,
      allowCommentsProfile: true,
      beta: betaMember,
      newsletterBeta: betaMember && getNewsletterBeta,
      newsletterCreator: getNewsletterCreator,
      newsletterGeneral: getNewsletterGeneral,
      uid: user.uid,
    };

    settingsRef
      .doc(user.uid)
      .set(settings)
      .then(function () {
        if (duplicateOnProd) {
          hardcodedProdSettingsRef
            .doc(user.uid)
            .set(settingsRef)
            .then(function () {
              if (!props.withoutReroute) {
                history.push("/user/" + user.username);
              }
            })
            .catch(function (error) {
              console.log(error);
              if (!props.withoutReroute) {
                history.push("/user/" + user.username);
              }
            })
        } else {
          if (!props.withoutReroute) {
            history.push("/user/" + user.username);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        if (!props.withoutReroute) {
          history.push("/user/" + user.username);
        }
      })
  }

  function switchToSignin() {
    if (props.onSwitchToSignin) {
      props.onSwitchToSignin();
    } else {
      history.push("/SignIn");
    }
  }

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  function avatarClick() {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  }

  function handleFile(e?: React.ChangeEvent<HTMLInputElement>) {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setAvatarFileSrc(fileReader?.result?.toString());
    };
    fileReader.readAsDataURL(e?.target.files ? e.target.files[0] : new Blob());
  }

  function handleAvatarSet(file: File) {
    setAvatarFile(file);
    setAvatarFileSrc(undefined);
  }

  function handleAvatarCancel() {
    setAvatarFile(undefined);
    setAvatarFileSrc(undefined);
    setCroppedImageURL("");
  }

  return (
    <div>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography align="center" variant="h5">
            Content creators, would you like to make your content available
            to a wider fanbase without risking revenue by lowering prices?
            <br/>
            <br/>
          </Typography>
          <Typography align="center" variant="h5">
            Pervs, do you love free adult content but wish you could be
            supporting creators in the process?
            <br/>
            <br/>
          </Typography>
          <Typography align="center" variant="h4">
            Welcome to <i>Fannr</i>, a new website designed to solve both
            of these problems while also aiming to provide more power to
            creators by providing a platform for feedback on how to manage
            the site.
          </Typography>
          <br />
          <br />
          <FAQS />
          <br />
          <br />
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Avatar
            className={classes.avatar}
            src={croppedImageURL}
            onClick={avatarClick}
          />
          Tap avatar icon to set profile photo
          <Modal
            open={avatarFileSrc !== undefined}
            onClose={handleAvatarCancel}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "25%",
            }}
          >
            <AvatarUploader
              withSrc={avatarFileSrc}
              onCrop={(url) => setCroppedImageURL(url)}
              onSave={handleAvatarSet}
              onCancel={handleAvatarCancel}
            />
          </Modal>
          <input
            type="file"
            accept="image/*"
            id="profile_pic"
            ref={hiddenFileInput}
            onChange={handleFile}
            style={{ display: "none" }}
          />
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={inputErrorDob}
                  helperText={inputHelperTextDob}
                  onBlur={() => setCanValidateDob(true)}
                  id="date"
                  label="Date of Birth"
                  type="date"
                  variant="outlined"
                  value={dob}
                  style={{width: "100%"}}
                  onChange={(e) => setDob(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={inputErrorUsername}
                  helperText={inputHelperTextUsername}
                  onBlur={() => setCanValidateUsername(true)}
                  // autoComplete="username"
                  name="username"
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="username"
                  value={username}
                  onChange={(event) => setUsername(event.target.value.trim())}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={inputErrorDisplayName}
                  helperText={inputHelperTextDisplayName}
                  onFocus={() => setCanValidateDisplayName(true)}
                  variant="outlined"
                  required
                  fullWidth
                  id="displayName"
                  label="Display Name"
                  name="displayName"
                  autoComplete="displayName"
                  value={displayName}
                  onChange={(event) => setDisplayName(event.target.value.substring(0, 15))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={inputErrorEmail}
                  helperText={inputHelperTextEmail}
                  onBlur={() => setCanValidateEmail(true)}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value.trim())}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={inputErrorEmailVerify}
                  helperText={inputHelperTextEmailVerify}
                  onBlur={() => setCanValidateEmailVerify(true)}
                  variant="outlined"
                  required
                  fullWidth
                  id="emailConfirm"
                  label="Confirm Email Address"
                  name="emailConfirm"
                  autoComplete="email"
                  value={emailConfirm}
                  onChange={(event) => setEmailConfirm(event.target.value.trim())}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={inputErrorPassword}
                  helperText={inputHelperTextPassword}
                  onBlur={() => setCanValidatePassword(true)}
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={inputErrorPasswordVerify}
                  helperText={inputHelperTextPasswordVerify}
                  onBlur={() => setCanValidatePasswordVerify(true)}
                  variant="outlined"
                  required
                  fullWidth
                  name="confirm password"
                  label="Confirm Password"
                  type="password"
                  id="confirm password"
                  autoComplete="current-password"
                  value={passwordConfirm}
                  onChange={(event) => setPasswordConfirm(event.target.value)}
                />
              </Grid>
              {process.env.NODE_ENV === "development" &&
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={duplicateOnProd}
                        onChange={(e) => setDuplicateOnProd(e.target.checked)}
                      />
                    }
                    label="Duplicate account on production database?"
                  />
                </Grid>
              }
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={betaMember}
                      onChange={(e) => setBetaMember(e.target.checked)}
                    />
                  }
                  label={SIGNUP_STRINGS.JOIN_BETA}
                />
              </Grid>

              {betaMember &&
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getNewsletterBeta}
                        onChange={(e) => setGetNewLetterBeta(e.target.checked)}
                      />
                    }
                    label={SIGNUP_STRINGS.GET_NEWSLETTER_BETA}
                  />
                </Grid>
              }
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getNewsletterCreator}
                      onChange={(e) => setGetNewLetterCreator(e.target.checked)}
                    />
                  }
                  label={SIGNUP_STRINGS.GET_NEWSLETTER_CREATOR}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={getNewsletterGeneral}
                      onChange={(e) => setGetNewLetterGeneral(e.target.checked)}
                    />
                  }
                  label={SIGNUP_STRINGS.GET_NEWSLETTER_GENERAL}
                />
              </Grid>
            </Grid>
            {/* add tooltip */}
            <div onMouseDown={() => setCanValidateAll(true)}>
              <Button
                disabled={!canSignUp}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={SignUp}
                onMouseDown={() => setCanValidateAll(true)}
              >
                Sign Up
              </Button>
            </div>
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  key={"SignIn"}
                  onClick={() => switchToSignin()}
                  className="JDHeaderLink"
                >
                  Already have an account? Sign in
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}
