import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DB,
  projectId: process.env.REACT_APP_PID,
  storageBucket: process.env.REACT_APP_SB,
  messagingSenderId: process.env.REACT_APP_SID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MID,
};
firebase.initializeApp(config);

const route = process.env.NODE_ENV + "_";

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const imagesRef = storage.ref("images");
export const viewsRef = firestore.collection(route + "views");
export const usersRef = firestore.collection(route + "users");
export const postsRef = firestore.collection(route + "posts");
export const settingsRef = firestore.collection(route + "settings");

export const hardcodedProdViewsRef = firestore.collection("production_views");
export const hardcodedProdUsersRef = firestore.collection("production_users");
export const hardcodedProdPostsRef = firestore.collection("production_posts");
export const hardcodedProdSettingsRef = firestore.collection("production_settings");

export default firebase;
