import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState, useContext } from "react";
import "./Profile.css";
import StickyBox from "react-sticky-box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import VizSensor from "react-visibility-sensor";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { css } from "@emotion/core";
import PacmanLoader from "react-spinners/PacmanLoader";
import { useParams } from "react-router-dom";
import { auth, imagesRef, postsRef, usersRef } from "../firebase";
import { FannOther, FannSelf, FannOtherDataKeys } from "../types/Fann";
import SignInRequired from "../components/SignInRequired";
import BannerFooter from "../components/BannerFooter";
import NewPostUploader, { slideProperties } from "../components/NewPostUploader";
import { UserContext } from "../context/UserContext";
import { Post, PostStrings } from "../types/Post"
import "../components/NewPostUploader.css";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";

interface RouteParams {
  username: string;
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "100%", // 1:1
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export default function Profile() {
  const classes = useStyles();

  const [content, setContent] = useState(cards);
  const [isLoading, setIsLoading] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [catchPhrase, setCatchPhrase] = useState("");
  const [bio, setBio] = useState("");
  const [avatarURL, setAvatarURL] = useState("");
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [currentUser, setCurrentUser] = useState<
    FannOther | FannSelf | undefined
  >();
  const [userQueryError, setUserQueryError] = useState<any>(null);
  const [userPosts, setUserPosts] = useState<Post[]>();
  const [isDirty, setIsDirty] = useState(false);
  const [showContentArrows, setShowContentArrows] = useState(true);

  const { state } = useContext(UserContext);

  const maxDisplayNameCharacters = 15;
  const maxCatchPhraseCharacters = 60;
  const maxBioCharacters = 1000;

  const { username } = useParams<RouteParams>();

  useEffect(() => {
    //clearing unused var warning, remove this later
    console.log("Dirty:", isDirty);
  }, [isDirty])

  useEffect(() => {
    if (
      (!currentUser && !userQueryError) ||
      currentUser?.username !== username
    ) {
      usersRef
        .where(FannOtherDataKeys.usernameLowercase, "==", username.toLowerCase())
        .get()
        .then((docs) => {
          if (docs.docs.length > 0) {
            try {
              const u = docs.docs[0].data() as FannOther;
              setCurrentUser(u);
              setIsCurrentUser(auth.currentUser?.uid === u.uid);
              setDisplayName(u.displayName);
              setBio(u.bio || "");
              setAvatarURL(u.photoURL || "");
              setCatchPhrase(u.catchPhrase || "");
              setUserQueryError(null);
              getPosts(u.uid);
              if (u.photoURL === "" || !u.photoURL) {
                imagesRef
                  .child("/profile/" + u.uid)
                  .getDownloadURL()
                  .then(function (url) {
                    setAvatarURL(url);
                  })
              }
            } catch (error) {
              console.log(error);
              setUserQueryError(error);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setUserQueryError(error);
        });
    }
  });

  function getPosts(uid: string) {
    postsRef
      .where(PostStrings.ownerUids, "array-contains", uid)
      .orderBy("postTime", "desc")
      .get()
      .then(function (postsResult) {
        setUserPosts(postsResult.docs.map((post => {
          return post.data() as Post;
        })))
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    setIsDirty(bio !== state.user?.bio || catchPhrase !== state.user.catchPhrase || avatarURL !== state.user.photoURL)
  }, [bio, catchPhrase, avatarURL, state.user])

  useEffect(() => {
    setIsLoading(false);
  }, [content]);

  const updateCatchPhrase = (value: string) => {
    if (value.length <= maxCatchPhraseCharacters) {
      setCatchPhrase(value);
    }
  };

  const updateBio = (value: string) => {
    if (value.length <= maxBioCharacters) {
      setBio(value);
    }
  };

  const getMoreContent = () => {
    const nums: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    setIsLoading(true);
    //simulate actual loading experience
    setTimeout(function () {
      setContent([...content, ...nums]);
      setIsLoading(false);
    }, 5000);
  };

  return (
    <SignInRequired>
        <BannerFooter>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <StickyBox>
            <Paper style={{ margin: "1vw", padding: "1%", alignItems: "strech" }}>
              <Container style={{ width: "100%" }}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12}>
                    <Container>
                      <Avatar
                        src={avatarURL}
                        style={{
                          height: "15vw",
                          width: "15vw",
                          margin: "1.5vw",
                        }}
                      />
                    </Container>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ color: "#bbb" }}>
                      <i>{"@" + username}</i>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {isCurrentUser && (
                      <TextField
                        label="Display Name"
                        variant="outlined"
                        value={displayName}
                        helperText={
                          displayName.length.toString() +
                          "/" +
                          maxDisplayNameCharacters.toString()
                        }
                        onChange={(event) => setDisplayName(event.target.value)}
                        style={{ width: "100%" }}
                      />
                    )}
                    {!isCurrentUser && (
                      <Typography variant="h5">{displayName}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {isCurrentUser && (
                      <TextField
                        label="Catchphrase"
                        variant="outlined"
                        value={catchPhrase}
                        helperText={
                          catchPhrase.length.toString() +
                          "/" +
                          maxCatchPhraseCharacters.toString()
                        }
                        onChange={(event) =>
                          updateCatchPhrase(event.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    )}
                    {!isCurrentUser && (
                      <Typography variant="h6">
                        <i>{catchPhrase}</i>
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {isCurrentUser && (
                      <TextField
                        label="Bio"
                        variant="outlined"
                        value={bio}
                        helperText={
                          bio.length.toString() +
                          "/" +
                          maxBioCharacters.toString()
                        }
                        multiline
                        rows={4}
                        rowsMax={20}
                        onChange={(event) => updateBio(event.target.value)}
                        style={{ width: "100%" }}
                      />
                    )}
                    {!isCurrentUser && <Typography>{bio}</Typography>}
                  </Grid>
                </Grid>
              </Container>
            </Paper>
            {isCurrentUser &&
              <div style={{ margin: "1vw" }}>
                <NewPostUploader
                  onDone={() => getPosts(currentUser?.uid || auth.currentUser?.uid || "")}
                  onModalClose={() => setShowContentArrows(true)}
                  onModalOpen={() => setShowContentArrows(false)}
                  style={{ width: "100%" }}
                />
              </div>
            }
          </StickyBox>
          <Paper style={{ margin: "1vw", padding: "1%", width: "70vw" }}>
            <Container className={classes.cardGrid}>
              <Grid container spacing={4}>
                {userPosts && userPosts.filter(x => x.content).map((post: Post, index) => (post.content &&
                  <>
                    <Grid item key={index} xs={12} sm={6} md={4}>
                      <VizSensor>
                        <Card className={classes.card}>
                          <Slide
                            {...slideProperties}
                            arrows={showContentArrows &&  post.content.length > 1}
                            indicators={
                                post.content.length > 1 ?
                              (i: number) => <div className="indicator">{i + 1}</div> :
                              null
                            }
                          >
                            { post.content.sort((a, b) => a.contentIndex - b.contentIndex).map((each, index) => (
                                <img alt={"post_content_" + index} src={each.contentUrl} style={{backgroundColor: "black", width: "100%", height: "40vh", objectFit: "contain"}} />
                            ))}
                          </Slide>
                          <CardContent className={classes.cardContent}>
                            <Typography gutterBottom variant="overline">
                              <i>{(new Date(post.postTime)).toLocaleString()}</i>
                            </Typography>
                            <Typography>
                              {post.desription}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Button size="small" color="primary">
                              View
                            </Button>
                            <Button size="small" color="primary">
                              Edit
                            </Button>
                          </CardActions>
                        </Card>
                      </VizSensor>
                    </Grid>
                    {(index - 3) % 5 === 0 && (
                      <Grid
                        item
                        key={index.toString() + "_ad"}
                        xs={12}
                        sm={6}
                        md={4}
                      >
                        <VizSensor
                          onChange={(isVisible) => {
                            if (isVisible && content.length - index < 9) {
                              getMoreContent();
                            }
                          }}
                        >
                          <Card className={classes.card}>
                            <CardMedia
                              className={classes.cardMedia}
                              image="https://picsum.photos/500"
                              title="Image title"
                              style={{ backgroundColor: "#bbb" }}
                            />
                            <CardContent className={classes.cardContent}>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                              >
                                This advertiser didn't provide any details so
                                here's a joke :)
                              </Typography>
                              <Typography>Me :(</Typography>
                            </CardContent>
                            <CardActions>
                              <Button size="small" color="primary">
                                View
                              </Button>
                              <Button size="small" color="primary">
                                Edit
                              </Button>
                            </CardActions>
                          </Card>
                        </VizSensor>
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
              <br />
              <div className="sweet-loading">
                <PacmanLoader
                  css={override}
                  size={30}
                  color={"#bbb"}
                  loading={isLoading}
                />
              </div>
            </Container>
          </Paper>
        </div>
      </BannerFooter>
    </SignInRequired>
  );
}
