import React from "react";
import "./App.css";
import Album from "./pages/Album";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { UserProvider } from "./context/UserContext";

function App() {
  const routes = [
    {
      path: "/SignUp",
      exact: true,
      name: "SignUp",
      main: () => <SignUp />,
    },
    {
      path: "/SignIn",
      exact: true,
      name: "SignIn",
      main: () => <SignIn />,
    },
    {
      path: "/Dashboard",
      exact: true,
      name: "Dashboard",
      main: () => <Dashboard />,
    },
    {
      path: "/Album",
      exact: true,
      name: "Album",
      main: () => <Album />,
    },
    {
      path: "/user/:username",
      exact: true,
      name: "Profile",
      main: () => <Profile />,
    },
  ];

  const defaultComponent = () => <SignUp />;

  const mappedRoutes = routes.map((route, index) => (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      component={route.main}
    />
  ));

  return (
    <Router>
      <UserProvider>
        <Switch>
          {mappedRoutes}
          <Route key={routes.length} component={defaultComponent} />
        </Switch>
      </UserProvider>
    </Router>
  );
}

export default App;
