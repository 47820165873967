import React, { useReducer, Dispatch, createContext } from "react";
import { FannSelf } from "../types/Fann";
import { userReducer, messageCountReducer, notificationCountReducer, UserActions, MessageCountActions, NotificationCountActions } from "./UserReducer";

type UserContextType = {
  user?: FannSelf;
  messageCount: number;
  notificationCount: number;
}

const defaultContext: UserContextType = { messageCount: 0, notificationCount: 0 };

const UserContext = createContext<{
  state: UserContextType;
  dispatch: Dispatch<UserActions | MessageCountActions | NotificationCountActions>;
}>({
  state: defaultContext,
  dispatch: () => null,
});
const mainReducer = ({ user, messageCount, notificationCount }: UserContextType,
  action: UserActions | MessageCountActions | NotificationCountActions): UserContextType => ({
    user: userReducer(user, action as UserActions),
    messageCount: messageCountReducer(messageCount, action as MessageCountActions),
    notificationCount: notificationCountReducer(notificationCount, action as NotificationCountActions),
});
const UserProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, defaultContext);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
export { UserContext, UserProvider };
